import React from "react";
import HeroSection from "./components/HeroSection";
import DataIntegrationSection from "./components/DataIntegrationSection";
import PersonalizedAnalyticsSection from "./components/PersonalizedAnalyticsSection";
import NutritionInsightsSection from "./components/NutritionInsightsSection";
import RecoveryInsightsSection from "./components/RecoveryInsightsSection";
import CallToActionSection from "./components/CallToActionSection";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <HeroSection />
      <DataIntegrationSection />
      <PersonalizedAnalyticsSection />
      <NutritionInsightsSection />
      <RecoveryInsightsSection />
      <CallToActionSection />
      <Footer />
    </div>
  );
}

export default App;