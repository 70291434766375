import React from "react";

function PersonalizedAnalyticsSection() {
  return (
<section className="bg-gradient-to-r from-teal-900 to-teal-600 py-16">
  <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-8 px-6">
    {/* Image à gauche */}
    <div className="flex justify-center md:justify-start">
      <img
        src="/images/personalized_analytics.png"
        alt="Personalized Analytics"
        className="rounded-lg shadow-lg w-full max-w-sm md:max-w-md lg:max-w-lg"
      />
    </div>

    {/* Texte à droite */}
    <div className="text-center md:text-left text-white">
      <h2 className="text-3xl font-bold mb-4">
        Track Your Progress Like Never Before
      </h2>
      <p className="text-lg mb-6">
        Visualize your progress through customizable graphs and performance trends. Dive deep into your data to uncover insights that fuel your performance.
      </p>
      <ul className="space-y-2">
        <li>📊 Interactive charts to track progress.</li>
        <li>📈 Compare performance trends over time.</li>
        <li>⚡ Insights into key metrics like speed, calories, and heart rate.</li>
      </ul>
    </div>
  </div>
</section>
  );
}

export default PersonalizedAnalyticsSection;