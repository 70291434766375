import React from "react";

function RecoveryInsightsSection() {
  return (
    <section className="bg-gradient-to-r from-teal-900 to-teal-600 py-16">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-8 px-6">
        {/* Texte à gauche */}
        <div className="text-center md:text-left text-white">
          <h2 className="text-3xl font-bold mb-4">
            Optimize Recovery and Manage Pain
          </h2>
          <p className="text-lg mb-6">
            Track your recovery trends and log areas of discomfort to gain insights into your body’s needs and prevent injuries.
          </p>
          <ul className="space-y-2">
            <li>🌀 Monitor daily recovery trends.</li>
            <li>📍 Identify and track pain points on an interactive body map.</li>
            <li>⚡ Prevent injuries with actionable insights.</li>
          </ul>
        </div>

        {/* Image à droite */}
        <div className="flex justify-center md:justify-end">
          <img
            src="/images/recovery_insights.png"
            alt="Recovery Insights"
            className="rounded-lg shadow-lg w-full max-w-sm md:max-w-md lg:max-w-lg"
          />
        </div>
      </div>
    </section>
  );
}

export default RecoveryInsightsSection;