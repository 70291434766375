import React from "react";

function HeroSection() {
  return (
    <div
      className="relative bg-cover min-h-[80vh] flex items-center justify-center"
      style={{
        backgroundImage: "url('/images/header.png')",
        backgroundPosition: "center 30%", // Desktop : focus décalé en dessous du centre
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Content */}
      <div className="relative z-10 text-center text-white">
        {/* Logo */}
        <img
          src="/images/logo.png"
          alt="AthleteDataHub Logo"
          className="w-24 h-24 mb-4 mx-auto"
        />

        {/* Text Overlay */}
        <h1 className="text-5xl font-bold mb-4">Athlete Data Hub</h1>
        <p className="text-lg">
          Centralize, analyze, and optimize your athletic performance.
        </p>
      </div>
    </div>
  );
}

export default HeroSection;