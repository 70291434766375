import React from "react";

function NutritionInsightsSection() {
  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-6">
        {/* Titre principal */}
        <h2 className="text-3xl font-bold text-center text-teal-900 mb-12">
          Fuel Your Body for Peak Performance
        </h2>

        {/* Sous-sections */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Sous-section 1 */}
          <div className="bg-white shadow-md p-6 rounded-lg text-center">
            <div className="text-teal-900 mb-4">
              <i className="fas fa-chart-pie text-5xl"></i>
            </div>
            <h3 className="text-xl font-bold text-gray-800 mb-2">
              Macronutrient Tracking
            </h3>
            <p className="text-gray-600">
              Visualize your protein, carbs, and fat intake to optimize your diet for training and recovery.
            </p>
          </div>

          {/* Sous-section 2 */}
          <div className="bg-white shadow-md p-6 rounded-lg text-center">
            <div className="text-teal-900 mb-4">
              <i className="fas fa-dumbbell text-5xl"></i>
            </div>
            <h3 className="text-xl font-bold text-gray-800 mb-2">
              Recovery & Body Composition
            </h3>
            <p className="text-gray-600">
              Understand how nutrition supports muscle recovery and impacts your body composition. Track changes and align with your performance goals.
            </p>
          </div>

          {/* Sous-section 3 */}
          <div className="bg-white shadow-md p-6 rounded-lg text-center">
            <div className="text-teal-900 mb-4">
              <i className="fas fa-running text-5xl"></i>
            </div>
            <h3 className="text-xl font-bold text-gray-800 mb-2">
              Performance Fueling
            </h3>
            <p className="text-gray-600">
              Learn how to time your meals and snacks to fuel your workouts and maximize endurance, strength, and recovery.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NutritionInsightsSection;