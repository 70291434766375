import React, { useState } from "react";

function CallToActionSection() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      setMessage("Thank you for your interest! Updates are coming your way.");
      setEmail(""); // Réinitialise le champ email
    } else {
      setMessage("Please enter a valid email address.");
    }
  };

  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-6 text-center">
        {/* Titre principal */}
        <h2 className="text-3xl font-bold text-gray-800 mb-6">
          Stay Connected with AthleteDataHub
        </h2>
        <p className="text-lg text-gray-600 mb-8">
          Enter your email to receive updates on the project!
        </p>

        {/* Formulaire */}
        <form
          onSubmit={handleSubmit}
          className="max-w-lg mx-auto bg-gray-100 p-6 rounded-lg shadow-lg"
        >
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-teal-500"
            required
          />
          <button
            type="submit"
            className="w-full bg-teal-700 text-white py-3 rounded-lg hover:bg-teal-800 transition font-bold"
          >
            Get informed
          </button>
        </form>

        {/* Message de confirmation */}
        {message && <p className="text-green-500 mt-4">{message}</p>}
      </div>
    </section>
  );
}

export default CallToActionSection;