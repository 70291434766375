import React from "react";

function DataIntegrationSection() {
  return (
    <section className="bg-gradient-to-b from-gray-50 to-white py-16">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-8 px-6">
        {/* Texte à gauche */}
        <div className="text-center md:text-left">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">
            Seamlessly Connect Your Devices
          </h2>
          <p className="text-gray-600 mb-6">
            Bring all your fitness data into one place by integrating your Garmin, Strava, Apple Health, FatSecret, and other popular fitness platforms.
          </p>
          <ul className="text-gray-600 space-y-2">
            <li>✅ Compatible with top fitness apps and devices.</li>
            <li>✅ Real-time sync to keep your data always updated.</li>
            <li>✅ A unified view of all your metrics.</li>
          </ul>
        </div>

        {/* Image à droite */}
        <div className="flex justify-center md:justify-end">
          <img
            src="/images/data_integration.png"
            alt="Data Integration"
            className="rounded-lg shadow-lg w-full max-w-sm md:max-w-md lg:max-w-lg"
          />
        </div>
      </div>
    </section>
  );
}

export default DataIntegrationSection;